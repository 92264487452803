<template>
    <section class="wine">
        <div class="wine__scroll-target"></div>
        <div class="wine__inner">
            <div class="wine__left-col">
                <div class="wine__image" data-scroll data-scroll-sticky data-scroll-target=".wine__scroll-target">
                    <img :src="get(pageData, 'image[0].url')" :alt="get(pageData, 'image[0].title') ||
                        get(pageData, 'title') ||
                        'GWS'
                        " />
                </div>
            </div>

            <div class="wine__right-col">
                <div class="wine__title wine__title--desktop">
                    <h1>{{ get(pageData, "title") }}</h1>
                    <h2>{{ get(pageData, "teaser_1") }}</h2>
                    <h3>{{ get(pageData, "teaser_2") }}</h3>
                    <hr />
                </div>
                <div class="wine__title wine__title--mobile">
                    <div class="wine__title-inner">
                        <div class="wine__image wine__title-left-col">
                            <img :src="get(pageData, 'image[0].url')" :alt="get(pageData, 'image[0].title') ||
                        get(pageData, 'title') ||
                        'GWS'
                        " />
                        </div>
                        <div class="wine__title-right-col">
                            <h1>{{ get(pageData, "title") }}</h1>
                            <h2>{{ get(pageData, "teaser_1") }}</h2>
                            <h3>{{ get(pageData, "teaser_2") }}</h3>
                        </div>
                    </div>

                    <hr />
                </div>
                <div id="google_translate_element"></div>

                <div class="wine__tab" :class="{ 'wine__tab--bg': index === 0 }"
                    v-for="(tab, index) in tabsData" v-html="get(tab, 'content')" :key="index">
                </div>
                <div class="wine__footer">
                    <a v-if="includes(redirectUrl, 'http')" class="wine__other"
                        :href="get(pageData, 'other_wines_url')">
                        {{ _tr("Other Wines") }}
                    </a>
                    <router-link v-else class="wine__other" :to="get(pageData, 'other_wines_url')">
                        {{ _tr("Other Wines") }}
                    </router-link>
                </div>
            </div>
        </div>
  
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import { get, includes } from "lodash";

export default {
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            get,
            includes
        };
    },
    computed: {
        ...mapGetters({
            lang: "language/getLang"
        }),
        pageData() {
            return this.data?.list?.find(wine =>
                this.$route.params.lang === "wines"
                    ? wine?.slug === this.$route.params.path1
                    : wine?.slug === this.$route.params.path2
            );
        },
        redirectUrl() {
            // Get redirect url
            return get(this.pageData, "other_wines_url");
        },
        tabsData() {
            const tabsContent = get(this.pageData, 'description.tabs')
            if (!tabsContent) return;
            [tabsContent[0], tabsContent[1]] = [tabsContent[1], tabsContent[0]];
            return tabsContent
        }
    },
    methods: {
        loadGoogleTranslateScript() {
            const script = document.createElement("script");
            script.src =
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            script.async = true;
            document.head.appendChild(script);
            window.googleTranslateElementInit = () => {
                new window.google.translate.TranslateElement(
                    {
                        pageLanguage: this.locale === "ge" ? "ka" : this.locale,
                        includedLanguages:
                            "pl,lt,lv,et,de,fr,pt-PT,es,ru,zh-CN,ja,ko,hy,tr,ar,it,cs,nl,ka,en"
                    },
                    "google_translate_element"
                );
            };
        }
    },
    mounted() {
        this.loadGoogleTranslateScript();
    }
};
</script>

<style lang="scss">
.wine {
    position: relative;
    background-color: #fff;
    width: 100%;

    &__inner {
        position: relative;
        display: grid;
        grid-template-columns: 28.57142857142857% 1fr;
        grid-column-gap: 180px;
        max-width: 1920px;
        margin: 0 auto;
        padding-left: 225px;
        padding-right: 225px;
        padding-top: 185px;
        padding-bottom: 67px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
    }

    .goog-te-gadget {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 34px;
        content: '';
        font-size: 0;

        select {
            padding: 5px;
            font-size: 16px;
        }

        a {
            display: none;
        }
    }

    @media (max-width: 1919px) {
        &__inner {
            padding-left: 75px;
            padding-right: 75px;
            grid-template-columns: 31.782945736434108% 1fr;
            grid-column-gap: 140px;
        }
    }

    @media (max-width: 1439px) {
        &__inner {
            padding-left: 47px;
            padding-right: 47px;
            grid-template-columns: 31.182795698924732% 1fr;
            grid-column-gap: 34px;
            padding-top: 152px;
        }
    }

    @media (max-width: 1023px) {
        &__inner {
            grid-template-columns: 1fr;
            grid-column-gap: initial;
            padding-top: 165px;
        }
    }

    @media (max-width: 767px) {
        &__inner {
            padding-top: 96px;
            padding-left: 16px;
            padding-right: 16px;
        }

        &__footer {
            flex-direction: column;
            gap: 10px;
        }
    }

    * {
        text-align: left;
        color: #000;
    }

    h1 {
        font-size: 89px;
        font-weight: 400;
        line-height: 121px;

        @media (max-width: 1439px) {
            font-size: 55px;
            line-height: 75px;
        }

        @media (max-width: 767px) {
            font-size: 34px;
            line-height: 46px;
            margin-bottom: 10px;
        }
    }

    h2 {
        font-size: 55px;
        font-weight: 400;
        line-height: 75px;
        margin-bottom: 5px;

        @media (max-width: 1439px) {
            font-size: 34px;
            font-weight: 400;
            line-height: 46px;
            margin-bottom: 15px;
        }

        @media (max-width: 767px) {
            font-size: 21px;
            line-height: 28px;
        }
    }

    h3 {
        font-size: 34px;
        font-weight: 400;
        line-height: 46px;

        @media (max-width: 1439px) {
            font-size: 21px;
            font-weight: 400;
            line-height: 29px;
        }

        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 21px;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;

        &--mobile {
            display: none;
        }

        hr {
            margin-top: 34px;
            margin-bottom: 34px;
        }

        @media (max-width: 1023px) {
            &--desktop {
                display: none;
            }

            &--mobile {
                display: flex;
                flex-direction: column;
            }

            &-inner {
                display: grid;
                grid-template-columns: 40.35608308605341% 1fr;
                grid-column-gap: 21px;
            }

            &-right-col {
                display: flex;
                flex-direction: column;
            }

            hr {
                margin-top: 46px;
            }
        }

        @media (max-width: 767px) {
            &-inner {
                display: flex;
                flex-direction: column;
                grid-template-columns: initial;
                grid-column-gap: initial;
            }

            &-right-col {
                order: 1;
                margin-bottom: 21px;
            }

            &-left-col {
                order: 2;
            }

            hr {
                margin-top: 38px;
            }
        }
    }

    &__image {
        position: relative;
        height: auto0;
        width: 100%;

        img {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__tab {
        margin-bottom: 34px;

        &--bg {
            background-color: #f3efe6;
            padding: 34px;
        }
    }

    &__scroll-target {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 340px);
        width: 0;

        @media (max-width: 1439px) {
            height: calc(100% - 252px);
        }
    }

    &__other {
        font-size: 21px;
        font-weight: 400;
        line-height: 29px;
    }

    &__left-col {
        @media (max-width: 1023px) {
            display: none;
        }
    }
}
</style>
